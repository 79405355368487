import React from 'react';
import { Component } from 'react';
import {
    BrowserRouter as Router,
    Link,
    useParams
  } from "react-router-dom";
 

class Card extends Component {     
    render () {     
         
        return (
            <div className="card">
                <img src={this.props.card.immagine} className="card-img-top" alt={this.props.card.nome} />
                <div className="card-body">
                    <h5 className="card-title">{this.props.card.nome}</h5>
                    <p className="card-text">€{this.props.card.prezzo.toFixed(2)}</p>
                    <p className="card-text d-none">{this.props.card.descrizione}</p>
                    
                    <Link to="/contact" component={{ Card }} className="btn btn-tr">
                        Richiedi info
                    </Link>
                </div>
            </div>
        )
    }
}

export default Card;