import { Component } from 'react';
import { IconContext } from "react-icons";
import { FaHeart } from 'react-icons/fa/';
const Button = () => {
    return <IconContext.Provider value={{ color: 'red' }}><FaHeart  /></IconContext.Provider>;
  };
  const today = new Date();
  const year = today.getFullYear();
class Footer extends Component {
    render () {   
        return (
            <div className="Footer">
                <div className="copyright-info text-center">
                    <p>Tr Nutrition | Copyright © {year} Made with <Button/> By <a href="https://checkupdigitale.com" target="_blank" rel="noreferrer" >checkupdigitale</a>, All Rights Reserved</p>
                </div>
            </div>
        )
    }
} 

export default Footer