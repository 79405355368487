import React from 'react';
import { useParams } from "react-router-dom";



export default function Scheda(props) { 
    const { id } = useParams(); 
      return (
        <>
            <div className="container mt-5">
                <div className="row m-3">
                    <h2 className="mb-3">Scheda Prodotto : {id}</h2>
                    <p></p>
                </div>
            </div>
            
        </>
        )
    }


