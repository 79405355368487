import { Component } from 'react';
import './App.css';
import "./css/style.css"
import Navbar from "./components/navbar"
import Card from "./components/card"
import About from './components/about';
import Contact from './components/contact';
import Scheda from './components/scheda';
import Footer from './components/footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
/* IMMAGINI */
import perfectcacao from "./images/W241_perfect_whey_900_cacao.png";
import perfect2ciocc from "./images/W385_PERFECT-100-WHEY-DOPPIO-CIOCCOLATO-900g.png";
import perfectblackciocc from "./images/W243_PERFECT-WHEY_caffeciok-900g.png";
import perfectmp from "./images/W058_magnesio_Potassio_30buste.png";
import perfectav from "./images/W037_Essenziali_1300_200cpr.png";


class App extends Component {
 state = { 
    cards : [
      {id:0, nome:"Perfect Whey Cacao (W241)", prezzo:64.90, descrizione:"Perfect Whey è un integratore di elevata qualità di proteine whey, proteine del siero del latte, ottenute per microfiltrazione a flusso crociato e purificate con il processo cross-flow.<br/><br/>Grazie a questo procedimento le proteine whey Perfect Whey contiene lo spettro completo di tutte le frazioni proteiche del siero del latte. Il grado di purezza di Perfect Whey le permette di avere un altissimo valore biologico raggiungendo ben il 23% di BCAA per ogni dose (30 gr).<br/><br/>WHYsport Perfect Whey contiene 900 gr (30 porzioni) di 100% proteine whey isolate altamente digeribili e ad altissima solubilità.<br/><br/>Senza aspartame, senza glutine, senza lattosio e con meno dell’1% di grassi, WHYsport Perfect Whey 900 sono disponibili in sei meravigliosi gusti: cacao, vaniglia, caffè ciok, neutro, fragola e cioco cocco.<br/><br/>WHYsport Perfect Whey 900 sono particolarmente indicate per gli sportivi che ricercano solo il meglio #iamperfect.<br/><br/>Perfect Whey sono disponibili anche nel formato da 450g<br/><br/>Modalità d’uso: sciogliere 30 g di prodotto (3 misurini) in 200 ml di acqua.", immagine:perfectcacao },
      {id:1, nome:"Perfect Doppio Cioccolato (W385)", prezzo:64.90, descrizione:"", immagine:perfect2ciocc },
      {id:2, nome:"Perfect Whey Black Chocolate (W124)", prezzo:59.90, descrizione:"", immagine:perfectblackciocc },
      {id:3, nome:"Perfect Whey Magnesio Potassio (W053)", prezzo:6.90, descrizione:"", immagine:perfectmp },
      {id:4, nome:"Perfect Whey Essenziali 1300 200CPR (W037)", prezzo:39.90, descrizione:"", immagine:perfectav },
    ]
  }
  
  render () { 
    return (
    <>
    <div className="Home">
      <Router>
        <Navbar /> 
          <Routes>
            <Route exact path="/" element={
                  <div className="container mt-5">
                      <div className="row m-3">
                        {this.state.cards.map(card => (
                          <Card key={card.id} 
                          card={card}
                          />
                        ))}
                      </div>
                  </div>
              } />
            <Route exact path="/about" element={<About/>} />
            <Route exact path="/contact" element={<Contact/>} /> 
            <Route exact path="/scheda" element={<Scheda/>} />
          </Routes>
          <Footer/>             
      </Router>
      </div>
    </> 
    )
  }
}

export default App;