import { Component } from 'react';
import { Divider } from 'semantic-ui-react';

class About extends Component {
    render () {   
        return (
            <div className="About">
                <div className="container mt-5">
                    <div className="row m-3">
                        <h2 className="mb-3">About</h2>
                        <Divider className="divider0"/>
                        <p><strong>WHysport.<span>&nbsp;</span></strong>Sembra una domanda invece &egrave; la risposta. La risposta per chi cerca:</p>
                        <ul>
                        <li>un valido supporto nelle attivit&agrave; sportive</li>
                        <li>un alleato per la nostra energia quotidiana</li>
                        <li>un importante sostegno per raggiungere i propri obiettivi, sportivi e non</li>
                        </ul>
                        <p><strong>WHYsport.<span>&nbsp;</span></strong>Un acronimo focalizzato sullo sport ma non solo.</p>
                        <ul>
                        <li>Wellness = benessere</li>
                        <li>Health = salute</li>
                        <li>Youth = giovinezza</li>
                        </ul>
                        <p>Il marchio<span>&nbsp;</span><strong>WHYsport nasce nel 2011</strong><span>&nbsp;</span>per soddisfare le esigenze di un consumatore sempre pi&ugrave; attento e esigente.</p>
                        <p>In un mercato saturo di prodotti stranieri, il consumatore italiano inizia a cercare integratori che rispondano a rigorosi controlli sia nelle fasi di ricerca e selezione delle materie prime che in quelle di conservazione, confezionamento e distribuzione dei prodotti. Dall&rsquo;esperienza pluriennale nel settore dell&rsquo;integrazione sviluppata da Biovita, nasce cos&igrave; il marchio e la linea di prodotti WHYsport (2 prodotti nel 2011 &ndash; 78 prodotti nel 2021), studiata da un team di esperti e professionisti sempre alla ricerca delle formulazioni e degli ingredienti pi&ugrave; efficaci e sicuri.</p>
                        <p><strong>10 motivi per sceglIere WHYsport</strong></p>
                        <ol>
                        <li>10 anni di esperienza nel settore</li>
                        <li>Ampia gamma di prodotti per tutti i tipi di sport e sportivi</li>
                        <li>Team dedicato di ricerca e sviluppo</li>
                        <li>Team di vendita e consulenza in tutta Italia</li>
                        <li>Utilizzo di materie prime certificate (TM System, Ciclodestrine, CFM Whey Protein, ecc)</li>
                        <li>Assenza e/o limitazioni nell&rsquo;uso di allergeni</li>
                        <li>Produzione in piccoli lotti per garantire freschezza</li>
                        <li>Packaging conformi alle normative</li>
                        <li>Testimonial e ambassador di comprovato profilo</li>
                        </ol>
                        <p>E soprattutto&hellip;</p>
                        <ol>
                        <li>Passione e dedizione per tutto quello che facciamo!</li>
                        </ol>
                        <p><strong>WHYsport.<span>&nbsp;</span></strong>Il nostro logo riassume tutto quello che siamo.</p>
                        <p>Il rosso &egrave; il colore del sangue, dei muscoli e del cuore. Il colore dell&rsquo;aggressivit&agrave;, della voglia di fare, di vincere e di primeggiare. Il colore della passione, della fierezza e dell&rsquo;orgoglio. Il colore del fuoco.</p>
                        <p>Il bianco &egrave; il colore della semplicit&agrave; e della purezza per eccellenza. Della sincerit&agrave;. Della fiducia.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default About