import React from 'react';
import { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';

import { slide as Menu } from 'react-burger-menu';
import logo from "../images/logo-tr-nutrition.png";

class Navbar extends Component{
    constructor (props) {
        super(props)
        this.state = {
          menuOpen: false
        }
      }
    
      // This keeps your state in sync with the opening/closing of the menu
      // via the default means, e.g. clicking the X, pressing the ESC key etc.
      handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})  
      }
      
      // This can be used to close the menu, e.g. when a user clicks a menu item
      closeMenu () {
        this.setState({menuOpen: false})
      }
    
      // This can be used to toggle the menu, e.g. when using a custom icon
      // Tip: You probably want to hide either/both default icons if using a custom icon
      // See https://github.com/negomi/react-burger-menu#custom-icons
      toggleMenu () {
        this.setState(state => ({menuOpen: !state.menuOpen}))
      }
    
        render() {
        return(
            <>
            <Menu itemListElement="div" 
                isOpen={this.state.menuOpen}
                onStateChange={(state) => this.handleStateChange(state)}>
                <Link to="/" onClick={() => this.closeMenu()}>
                    <li className="nav-item">
                        HOME
                    </li>
                </Link>
                
                <Link to="/about" onClick={() => this.closeMenu()}>
                    <li className="nav-item">
                        ABOUT
                    </li>
                </Link>
                
                <Link to="/contact" onClick={() => this.closeMenu()}>
                    <li className="nav-item">
                        CONTACT
                    </li>
                </Link>
            </Menu>

            <nav className="navbar navbar-expand-lg navbar-dark">
                <div className="container-fluid">
                    <span className="navbar-brand mb-0 h1"><img src={logo} alt="logo" className="logo" /></span>
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <NavLink className="navbar-item" activeclassname="is-active" to="/">
                            <li className="nav-item">
                                HOME
                            </li>
                        </NavLink>
                        
                        <NavLink className="navbar-item" activeclassname="is-active" to="/about">
                            <li className="nav-item">
                                ABOUT
                            </li>
                        </NavLink>
                        
                        <NavLink className="navbar-item" activeclassname="is-active" to="/contact">
                            <li className="nav-item">
                                CONTACT
                            </li>
                        </NavLink>
                    </ul>
                </div>
            </nav>
            <div className="collapse" id="navbarToggleExternalContent2">
                <ul className="bg-light shadow-3 py-2 px-3">
                        <Link to="/">
                            <li className="nav-item">
                                HOME
                            </li>
                        </Link>
                        
                        <Link to="/about">
                            <li className="nav-item">
                                ABOUT
                            </li>
                        </Link>
                        
                        <Link to="/contact">
                            <li className="nav-item">
                                CONTACT
                            </li>
                        </Link>
                </ul>
            </div>
            </>
        )
        }
    
}

export default Navbar;