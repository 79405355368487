import React from 'react';
import { Divider } from 'semantic-ui-react';
import emailjs from "emailjs-com";

emailjs.init('SN92J-1D6ikUavlWa');

const ContactForm = () => {
  const [formStatus, setFormStatus] = React.useState('Invia')
  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Invio in corso...')
    const { name, email, message } = e.target.elements
    
    let conFom = {
      name: name.value,
      email: email.value,
      message: message.value,
    }
    console.log(conFom)

    emailjs.send("service_jgd9gv2","template_6di1opr",{
      name: name.value,
      to_name: "Tonino",
      from_name: name.value,
      from_email: email.value,
      message: message.value,
      })
      .then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
      }, function(error) {
        console.log('FAILED...', error);
    });
    setFormStatus('Inviato');
  }

  return (
    <div className="container mt-5">
      <div className="row m-3">
        <h2 className="mb-3">Contatti</h2>
        <Divider className="divider0"/>
        <div className="col-sm">
          <form onSubmit={onSubmit} id="myForm">
            <div className="mb-3">
              <label className="form-label" htmlFor="name">
                Nome
              </label>
              <input className="form-control" type="text" id="name" required />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input className="form-control" type="email" id="email" required />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="message">
                Messaggio
              </label>
              <textarea className="form-control" id="message" required />
            </div>
            <button className="btn btn-tr" type="submit">
              {formStatus}
            </button>
          </form>
        </div>
        <div className="col-sm">
          <div className="contact-address text-center">
            <div className="address-list mb-4 mb-xl-10 pb-2">
              <h4 className="title">Ufficio</h4>
              <p>TR NUTRITION - Tonino Evangelista</p>
            </div>
            <div className="address-list mb-4 mb-xl-10 pb-2">
              <h4 className="title">Telefono</h4>
              <p>+39 347 349 6896</p></div>
              <div className="address-list">
                <h4 className="title">Indirizzi web</h4>
                <p>tonino74809@gmail.com <br/> www.tr-nutrition.com</p></div>
              </div>
            </div>
          </div>
        </div>
  )
}
export default ContactForm